import { configureStore } from "@reduxjs/toolkit"
import reducer from "./reducer"
import firebase from "firebase/app"
import firebaseConfig from "./firebaseConfig"
import "firebase/auth"
import "firebase/firestore"


firebase.initializeApp(firebaseConfig)
firebase.firestore()


export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //@redux/toolkit is offering extra protection for correct immutability and serialization of your state.
      //The previous configuration did not have this middleware
      //Solved those error with this lines
      immutableCheck: false,
      serializableCheck: false,
    }),
})

//Store type
export type RootState = ReturnType<typeof store.getState>
//Dispatch type
export type AppDispatch = typeof store.dispatch

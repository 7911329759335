export const PHONE_NUMBER_FOR_TEXT = "+54 9 11 3849-9171"

export const EMAIL_SUPPORT = "soporte-nest@cajadevalores.com.ar"
export const EMAIL_SUPPORT_URL = "mailto:soporte-nest@cajadevalores.com.ar"

export const EMAIL_ACCOUNT_OPENING = "aperturasylegajos@allariamas.com.ar"
export const EMAIL_ACCOUNT_OPENING_URL = "mailto:aperturasylegajos@allariamas.com.ar"

export const EMAIL_PAYMENT_LINK = "soportelink@allariamas.com.ar"
export const EMAIL_PAYMENT_LINK_URL = "mailto:soportelink@allariamas.com.ar"

export const STATIC_URL = import.meta.env.REACT_APP_STATIC_URL
import { PATH_APP } from "./paths"
import lazyCatchReload from "../components/utils/lazyCatchReload"
import LoadingBlankPage from "../components/loadingBlankPage/LoadingBlankPage"

const ChangePassword = lazyCatchReload(
  () => import("../components/authentication/ChangePassword")
)
const PasswordReset = lazyCatchReload(
  () => import("../components/authentication/PasswordReset")
)
const Login = lazyCatchReload(
  () => import("../components/authentication/Login")
)

const PasswordSafety = lazyCatchReload(
  () => import("../components/authentication/PasswordSafety")
)
const App = lazyCatchReload(() => import("../components/app/App"))

export const indexRoutesList = [
  {
    name: "Change password",
    path: PATH_APP.changePassword,
    children: <ChangePassword />,
  },
  {
    name: "Password safety",
    path: PATH_APP.passwordSafety,
    children: <PasswordSafety />,
  },
  {
    name: "Login",
    path: PATH_APP.login,
    children: <Login />,
  },
  {
    name: "Password reset",
    path: PATH_APP.resetPassword,
    children: <PasswordReset />,
  },
  {
    name: "Loading blank",
    path: PATH_APP.loadingBlank,
    children: <LoadingBlankPage />,
  },
  {
    name: "App",
    path: PATH_APP.multiAccountDashboard,
    children: <App />,
  },
]

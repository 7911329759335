export const PATH_APP = {
  //Password
  changePassword: "/change-password",
  passwordSafety: "/password",
  resetPassword: "/reset-password",

  //Login
  login: "/login",
  loadingBlank: "/loading-blank",

  //Dashboard
  multiAccountDashboard: "/",
  individualDashboard: "/dashboard",

  //Contacts
  contacts: "/contactos",
  newContact: "/nuevo-contacto",
  newContactConfirm: "/nuevo-contacto-confirmar",
  newContactApprove: "/nuevo-contacto-aprobar",
  newContactFinish: "/nuevo-contacto-fin",
  editContactSuccess: "/editar-contacto-success",
  approveContact: "/aprobar-contacto",
  editContact: "/contactos/:id",

  //Transactions
  newTransaction: "/nueva-transferencia",
  newMassiveTransaction: "/nueva-transferencia-masiva",

  //Reports
  reportSuccess: "/reporte/succes",
  reports: "/reportes",

  //Config
  config: "/configuracion",

  //FAQS
  faqs: "/preguntas-frecuentes",

  //Movements
  movements: "/movimientos",
  
  //Movements
  notFound: "*",
}

/* eslint-disable @typescript-eslint/no-var-requires, unicorn/prefer-module, no-undef, unicorn/prefer-default-parameters */
import firebase from "firebase/app"

import { v4 as uuidv4 } from "uuid"

import axios from "axios"
import dateFormat from "dateformat"

const axiosInstance = axios.create()

XMLHttpRequest.prototype.wrappedSetRequestHeader =
  XMLHttpRequest.prototype.setRequestHeader

XMLHttpRequest.prototype.setRequestHeader = function (header, value) {
  this.wrappedSetRequestHeader(header, value)

  if (!this.headers) {
    this.headers = {}
  }

  if (!this.headers[header]) {
    this.headers[header] = []
  }
  this.headers[header].push(value)
}

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {

    console.error(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.request.use((request) => {
  request.headers["X-UOW"] = generateUow()
  return request
})

export const basePaymentLinksUrl = import.meta.env
  .REACT_APP_PAYMENT_LINKS_BASE_URL
export const baseAuthenticationUrl = import.meta.env
  .REACT_APP_AUTHENTICATION_BASE_URL
export const baseInvestmentsUrl = import.meta.env.REACT_APP_INVESTMENTS_BASE_URL
export const baseUserManagementUrl = import.meta.env
  .REACT_APP_USER_MANAGEMENT_BASE_URL
export const baseCardUrl = import.meta.env.REACT_APP_CARD_BASE_URL
export const baseServicePaymentsUrl = import.meta.env
  .REACT_APP_SERVICE_PAYMENTS_BASE_URL
export const baseAllariaTransactionsUrl = import.meta.env
  .REACT_APP_ALLARIA_TRANSACTIONS_BASE_URL
export const baseConfigurationUrl = import.meta.env
  .REACT_APP_CONFIGURATION_BASE_URL
export const baseTransactionsUrl = import.meta.env
  .REACT_APP_TRANSACTIONS_BASE_URL
export const baseAllariaEcosystemUrl = import.meta.env
  .REACT_APP_ALLARIA_ECOSYSTEM_BASE_URL
export const baseEcheqsUrl = import.meta.env.REACT_APP_ECHEQS_BASE_URL
export const baseMepUrl = import.meta.env.REACT_APP_MEP_BASE_URL
export const baseAttachmentsUrl = import.meta.env.REACT_APP_ATTACHMENTS_BASE_URL

export const baseTransactionsUrlWalletManager = `${baseTransactionsUrl}/wallet-manager`
export const baseInvestmentsUrlWalletManager = `${baseInvestmentsUrl}/wallet-manager`

export function addTransaction(transaction, accountId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      const addTransaction = {
        //Math.ceil(parseFloat(props.amount * (taxes/100)) *100)/100
        amount: Number.parseFloat(
          Number(
            Number.parseFloat(transaction.amount) +
              Math.ceil(
                Number.parseFloat(transaction.amount) *
                  Number.parseFloat(transaction.taxes / 100) *
                  100
              ) /
                100
          ).toFixed(2)
        ),
        accountId,
        description: transaction.description,
        counterParty: {
          accountId: Number(transaction.receiver.destinationAccountId),
          cuit: transaction.receiver.cuit,
          name: transaction.receiver.name,
          personType: transaction.receiver.personType,
          [transaction.receiver.accountNumberType.toLowerCase()]:
            transaction.receiver.accountNumber,
          bank: transaction.receiver.bank,
          nickname: transaction.receiver.nickname,
          email: transaction.receiver.email,
          phoneNumber: transaction.receiver.phoneNumber,
        },
        notificationEmails: transaction.notificationEmail,
        currency: "ARS", //TODO: Esto funciona?
        tags: [],
      }
      if (transaction.scheduledDate) {
        addTransaction.scheduledDate = dateFormat(
          transaction.scheduledDate,
          "yyyy-mm-dd"
        )
      }
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/transactions`,
        addTransaction,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function repTransaction(transaction, accountId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      const addTransaction = {
        amount: Number(transaction.amount),
        accountId,
        description: transaction.description,
        counterParty: {
          accountId: Number(transaction.counterParty.accountId),
          cuit: transaction.counterParty.cuit,
          name: transaction.counterParty.name,
          personType: transaction.counterParty.personType,
          [transaction.counterParty.accountNumberType.toLowerCase()]:
            transaction.counterParty.accountNumber,
          bank: transaction.counterParty.bank,
          nickname: transaction.counterParty.nickname,
          email: transaction.counterParty.email,
          phoneNumber: transaction.counterParty.phoneNumber,
        },
        notificationEmails: transaction.notificationEmails,
        currency: "ARS", //TODO: Esto funciona?
        tags: [],
      }
      if (transaction.scheduledDate) {
        addTransaction.scheduledDate = dateFormat(
          transaction.scheduledDate,
          "yyyy-mm-dd"
        )
      }
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/transactions`,
        addTransaction,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function editNotification(
  accountId,
  userId,
  id,
  inbound,
  outbound,
  scheduled,
  tokenized
) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      putAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/notifications-settings/${id}`,
        {
          inbound,
          outbound,
          scheduled,
          tokenized,
        },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

// export function confirmTransaction(accountId, transactionId, token) {
//     return firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
//         .then(function (idToken) {
//             return postAxios(`${baseTransactionsUrlWalletManager}/accounts/${accountId}/transactions/${transactionId}/approve${false ? "?lock=false" : ""}`, {},
//                 {
//                     headers: { "Authorization": "JWT " + idToken, "X-Token": token },
//                 })
//         });
// }

export function getExternalAccount(elementType, elementCode, cuit) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/external-accounts/${elementCode}`,
        {
          params: {
            alias: elementType === "alias" || undefined,
            cuit,
          },
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function addContact(
  contact,
  accountId,
  phoneNumber,
  nickname,
  email,
  skipMail
) {
  skipMail = skipMail ? skipMail : false
  // console.log(contact)
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      let addContact
      addContact = contact.contactData
        ? {
            originAccountId: accountId,
            cuit: contact.contactData.cuit,
            name: contact.contactData.name,
            personType: contact.contactData.personType,
            nickname: contact.contactData.name,
            email: email ? email : null,
            [contact.contactData.accountNumberType.toLowerCase()]:
              contact.contactData.accountNumber,
            phoneNumber,
            bank: contact.contactData.bank,
          }
        : {
            originAccountId: accountId,
            cuit: contact.cuit,
            name: contact.name,
            personType: contact.personType,
            nickname: contact.name,
            email: email ? email : null,
            [contact.accountNumberType.toLowerCase()]: contact.accountNumber,
            phoneNumber,
            bank: contact.bank,
          }
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/contacts?skipMail=${skipMail}`,
        addContact,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function addSolicitude(solicitude, accountId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      const addSolicitude = {
        destinationAccountId: Number(solicitude.receiver.destinationAccountId),
        retrieveData: {
          amount: Number(solicitude.amount),
          comment: solicitude.description,
          includesRetrieve: solicitude.withRetrieve,
          investmentAccount: solicitude.investmentAccount,
        },
      }
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/solicitudes/retrieve`,
        addSolicitude,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function approveSolicitude(solicitude, accountId, token) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/solicitudes/${solicitude.id}/approve`,
        {},
        {
          headers: { Authorization: `JWT ${idToken}`, "X-Token": token },
          timeout: 2 * 1000,
        }
      )
    })
}

export function resendSolicitudeToken(accountId, solicitudeId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/solicitudes/${solicitudeId}/token`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function cancelSolicitude(accountId, solicitudeId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      deleteAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/solicitudes/${solicitudeId}`,
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function approveContact(contactId, accountId, token) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/contacts/${contactId}/approve`,
        {},
        {
          headers: {
            Authorization: `JWT ${idToken}`,
            "X-Token": token,
          },
          timeout: 30 * 1000,
        }
      )
    })
}

export function asyncApproveContact(contact, accountId, token, action) {
  return function (dispatch) {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        postAxios(
          `${baseTransactionsUrlWalletManager}/accounts/${accountId}/contacts/${contact.id}/approve`,
          {},
          {
            headers: {
              Authorization: `JWT ${idToken}`,
              "X-Token": token,
            },
          }
        )
          .then(() => {
            dispatch(action)
          })
          .catch((error) => console.log(`failed${error}`))
      })
      .catch(function (error) {
        alert("Failed adding contact")
        console.log(`Couldn't get user token${error}`)
      })
  }
}

export function resendContactToken(accountId, contactId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/contacts/${contactId}/token`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function deleteContact(accountId, contactId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return deleteAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/contacts/${contactId}`,
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function editContact(accountId, contact) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      putAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/contacts/${contact.id}`,
        {
          originAccountId: accountId,
          destinationAccountId: contact.accountId,
          ...contact,
          email: contact.email || null,
        },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function editAlias(accountId, newAlias) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return putAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/alias`,
        { alias: newAlias },
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function editDescription(accountId, userId, newDescription) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return putAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/description`,
        { description: newDescription },
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function getReport(accountId, start, end, format) {
  let startDate = `${start.getFullYear()}-${pad(start.getMonth() + 1, 2)}-${pad(
    start.getDate(),
    2
  )}`
  let endDate = `${end.getFullYear()}-${pad(end.getMonth() + 1, 2)}-${pad(
    end.getDate(),
    2
  )}`
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/reports/${startDate}/${endDate}/${format}`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
          responseType: "arraybuffer",
        }
      )
    })
}

export function getOrders(accountId, from, to) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      let params = {}
      from && (params = { ...params, from })
      to && (params = { ...params, to })
      return getAxios(
        `${baseTransactionsUrlWalletManager}/mutual-funds/orders/${accountId}`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
          params,
        }
      )
    })
}

export function getOrdersForAccount(accountId, from, to) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      let params = {}
      from && (params = { ...params, from })
      to && (params = { ...params, to })
      return getAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/orders`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
          params,
        }
      )
    })
}

export function getAccountStats() {
  return firebase
    .auth()
    .currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(`${baseTransactionsUrlWalletManager}/accounts/stats`, {
        headers: { Authorization: `JWT ${idToken}` },
        timeout: 30 * 1000,
      })
    })
}

export function getLoansStats() {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(`${baseTransactionsUrlWalletManager}/loans/stats`, {
        headers: { Authorization: `JWT ${idToken}` },
        timeout: 30 * 1000,
      })
    })
}

export function getSettlementStats() {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/mutual-fund-settlements/stats`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function getOrdersStats() {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/mutual-fund-orders/stats`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function getHolidays() {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/snapshots/holidays`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
        }
      )
    })
}

export function switchLending(accountId, active) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/peer-lending/${
          active ? "activate" : "deactivate"
        }`,
        undefined,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 40 * 1000,
        }
      )
    })
}

export function loginEpyme(accountId, userId, encryptedLogin) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/epyme/link`,
        encryptedLogin,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 20 * 1000,
        }
      )
    })
}

export function logoutEpyme(accountId, userId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/epyme/unlink`,
        {},
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 20 * 1000,
        }
      )
    })
}

export function resendPromissoryNoteTokenEpyme(
  accountId,
  userId,
  promissoryNoteId
) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/promissory-notes/${promissoryNoteId}/createSign`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function signPromissoryNoteEpyme(
  accountId,
  userId,
  promissoryNoteId,
  token
) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/promissory-notes/${promissoryNoteId}/sign`,
        {},
        { headers: { Authorization: `JWT ${idToken}`, "X-Token": token } }
      )
    )
}

export function cancelPromissoryNoteEpyme(
  accountId,
  userId,
  promissoryNoteId,
  reasonForRejection
) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/promissory-notes/${promissoryNoteId}/cancel`,
        { reasonForRejection },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function createPromissoryNoteEpyme(
  accountId,
  userId,
  promissoryNoteRequest
) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/promissory-notes`,
        promissoryNoteRequest,
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function getEpymeLinks(accountId, userId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/epyme/links`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
          crossdomain: true,
        }
      )
    })
}

export function getEpymeBailorAccounts(accountId, userId, endorsatorTaxId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/epyme/bailorAccounts/${endorsatorTaxId}`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
          crossdomain: true,
        }
      )
    })
}

export function getMutualFundsReport(accountId, start, end, format, fundId) {
  let startDate = `${start.getFullYear()}-${pad(start.getMonth() + 1, 2)}-${pad(
    start.getDate(),
    2
  )}`
  let endDate = `${end.getFullYear()}-${pad(end.getMonth() + 1, 2)}-${pad(
    end.getDate(),
    2
  )}`
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/reports/mutualFunds/${fundId}/${startDate}/${endDate}/${format}`,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 30 * 1000,
          responseType: "arraybuffer",
        }
      )
    })
}

export function updatePromissoryNoteEpyme(accountId, userId, promissoryNoteId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/promissory-notes/${promissoryNoteId}/update`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function updateEcheqEpyme(accountId, userId, echeqId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/echeqs/${echeqId}/update`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function resendEcheqTokenEpyme(accountId, userId, echeqId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/echeqs/${echeqId}/createSign`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function signEcheqEpyme(accountId, userId, echeqId, token) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/echeqs/${echeqId}/sign`,
        {},
        { headers: { Authorization: `JWT ${idToken}`, "X-Token": token } }
      )
    )
}

export function cancelEcheqEpyme(
  accountId,
  userId,
  echeqId,
  reasonForRejection
) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/echeqs/${echeqId}/cancel`,
        { reasonForRejection },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function createEcheqEpyme(accountId, userId, echeqRequest) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/users/${userId}/echeqs`,
        echeqRequest,
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    )
}

export function sendUserMessageEmail(customUserQuestionRequest, endpoint) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseTransactionsUrlWalletManager}/faq/${endpoint}`,
        customUserQuestionRequest,
        {
          headers: { Authorization: `JWT ${idToken}` },
          timeout: 20 * 1000,
        }
      )
    })
}

export function getAllariaAccounts(darumaAccountId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return getAxios(
        `${baseTransactionsUrlWalletManager}/allaria-accounts?accountId=${darumaAccountId}`,
        {
          headers: { Authorization: `JWT ${idToken}` },
        }
      )
    })
}

export function createLink(userId, accountId, allariaAccountId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAllariaEcosystemUrl}/links`,
        { userId, accountId, allariaAccountId },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function createRedemption(linkId, amount) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAllariaEcosystemUrl}/links/${linkId}/redemptions`,
        { amount },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function completeRedemption(linkId, redemptionId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAllariaEcosystemUrl}/links/${linkId}/redemptions/${redemptionId}/complete`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function cancelRedemption(linkId, redemptionId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAllariaEcosystemUrl}/links/${linkId}/redemptions/${redemptionId}/cancel`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function approveRedemption(linkId, redemptionId) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAllariaEcosystemUrl}/links/${linkId}/redemptions/${redemptionId}/approve`,
        {},
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function rejectedRedemption(linkId, redemptionId, reason) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseAllariaEcosystemUrl}/links/${linkId}/redemptions/${redemptionId}/reject`,
        { reason },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function userApproveAllariaLink(linkId, token) {
  return postAxios(
    `${baseTransactionsUrlWalletManager}/allaria-links/${linkId}/user-approve?token=${token}`,
    {},
    {
      headers: {},
      timeout: 30 * 1000,
    }
  )
}

export function allariaApproveAllariaLink(linkId, token) {
  return postAxios(
    `${baseTransactionsUrlWalletManager}/allaria-links/${linkId}/allaria-approve?token=${token}`,
    {},
    {
      headers: {},
      timeout: 30 * 1000,
    }
  )
}

export function allariaRejectLink(linkId, token) {
  return postAxios(
    `${baseTransactionsUrlWalletManager}/allaria-links/${linkId}/allaria-reject?token=${token}`,
    {},
    {
      headers: {},
      timeout: 30 * 1000,
    }
  )
}

export function updateMoneyMarketIntention(accountId, value) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return postAxios(
        `${baseTransactionsUrlWalletManager}/accounts/${accountId}/money-market/intentions`,
        { moneyMarket: value },
        { headers: { Authorization: `JWT ${idToken}` } }
      )
    })
}

export function acceptTermsAndConditions(accountId, feature) {
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return axios.post(
        `${basePaymentLinksUrl}/accounts/${accountId}/terms-and-conditions?feature=${feature}`,
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      )
    })
}

export const acceptUserTermsAndConditions = ({ userId, feature }) => {
  return firebase
    .auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)
    .then((idToken) =>
      patchAxios(
        `${baseCardUrl}/users/${userId}/terms-and-conditions?feature=${feature}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
    )
}

function pad(n, width, z) {
  z = z || "0"
  n = `${n}`
  return n.length >= width
    ? n
    : Array.from({ length: width - n.length + 1 }).join(z) + n
}

function generateUow() {
  return `IDYC-${uuidv4()}`
}

export function postAxios(url, body, options) {
  return axiosInstance.post(encodeURI(url), body, options)
}

export function putAxios(url, body, options) {
  return axiosInstance.put(encodeURI(url), body, options)
}

export function patchAxios(url, body, options) {
  return axiosInstance.patch(encodeURI(url), body, options)
}

export function deleteAxios(url, options) {
  return axiosInstance.delete(encodeURI(url), options)
}

export function getAxios(url, options) {
  return axiosInstance.get(encodeURI(url), options)
}
